import React from "react";
import "./switchPricing.css";

/**
 * The SwitchPricing function toggles between monthly and yearly pricing options when clicked.
 */
const SwitchPricing = (props) => {
  const { onClick, yearly } = props;

  const classes = ["switch-price-container"];
  if (yearly === true) {
    classes.push("second");
  }
  return (
    <div className={classes.join(" ")} onClick={onClick}>
      <div className="price-type" data-text="Monthly">
        Monthly
      </div>
      <div className="price-type" data-text="Yearly">
        Yearly
      </div>
    </div>
  );
};

export default SwitchPricing;
